import { DefaultThemeIntent, Dialog } from "evergreen-ui";
import React from "react";

/**
 * Props for the Alert component.
 *
 * @interface AlertProps
 * @property {string} message - The text message to display in the alert.
 * @property {(value: boolean) => void} onConfirm - Callback function called with a boolean value when the user confirms.
 * @property {(value: boolean) => void} onCancel - Callback function called with a boolean value when the user cancels.
 * @property {boolean} isConfirm - Indicates if the alert is a confirmation dialog.
 */
interface AlertProps {
  message: string;
  onConfirm: (value: boolean) => void;
  onCancel: (value: boolean) => void;
  intent: DefaultThemeIntent;
}

/**
 * Alert component for displaying a message to the user with optional confirmation buttons.
 *
 * @param {AlertProps} props - The props for the component.
 * @returns {JSX.Element} A portal rendering the alert modal.
 */
const Alert: React.FC<AlertProps> = ({
  message,
  onConfirm,
  onCancel,
  intent,
}: AlertProps): JSX.Element => {
  let title = "";
  switch (intent) {
    case "danger":
      title = "Niebezpieczeństwo";
      break;
    case "info":
      title = "Informacja";
      break;
    case "success":
      title = "Sukces";
      break;
    case "warning":
      title = "Ostrzeżenie";
      break;

    default:
      break;
  }

  return (
    <Dialog
      isShown={true}
      title={title}
      confirmLabel="Ok"
      cancelLabel="Anuluj"
      hasCancel={true}
      onCancel={() => onCancel(false)}
      onConfirm={() => onConfirm(true)}
      intent={intent}
    >
      {message}
    </Dialog>
  );
};

export default Alert;
