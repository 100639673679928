// src/GlobalContext.ts
import { createContext } from "react";
import { AppState } from "./types/AppState";

/**
 * Defines the shape of the context's value, including the application's state
 * and a function to update that state.
 *
 * @interface GlobalContextType
 * @property {Object} state - The current state of the application.
 * @property {string} [state.token] - The authentication token for the user.
 * @property {string} [state.expiration] - The expiration timestamp for the token.
 * @property {string} [state.user] - The user identifier or information.
 * @property {Function} state.showAlert - Function to display an alert message.
 * @property {Function} state.showConfirm - Function to display a confirmation dialog.
 * @property {Function} setState - Function to update the state.
 */
export interface GlobalContextType {
  state: AppState;
  setState: React.Dispatch<React.SetStateAction<AppState>>;
}

// Create the context with a default value of undefined
/**
 * The GlobalContext provides state management for global application state.
 * It is used to pass down and manage state across the application without
 * prop drilling.
 *
 * @type {React.Context<GlobalContextType | undefined>}
 */
const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export default GlobalContext;
