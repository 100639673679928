export type Province =
  | "DS"
  | "KP"
  | "LB"
  | "LD"
  | "LU"
  | "MA"
  | "MP"
  | "OP"
  | "PK"
  | "PL"
  | "PO"
  | "SK"
  | "SL"
  | "WM"
  | "WP"
  | "ZP"
  | ""
  | null;

export function isProvince(value: unknown): value is Province {
  return (
    value === "DS" ||
    value === "KP" ||
    value === "LB" ||
    value === "LD" ||
    value === "LU" ||
    value === "MA" ||
    value === "MP" ||
    value === "OP" ||
    value === "PK" ||
    value === "PL" ||
    value === "PO" ||
    value === "SK" ||
    value === "SL" ||
    value === "WM" ||
    value === "WP" ||
    value === "ZP" ||
    value === "" ||
    value === null
  );
}
