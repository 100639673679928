import { URLObject } from "./types/URLObject";
import {
  faBellConcierge,
  faChalkboardUser,
  faGavel,
  faHome,
  faPaperclip,
  faRightFromBracket,
  faRightToBracket,
  faScrewdriverWrench,
  faTrophy,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

interface APIEndpoints {
  host: string;
  login: string;
  logout: string;
  roles: string;
  refreshSession: string;
  sportClasses: string;
  player: {
    data: string;
    decodedPlayers: string;
    insert: {
      player: string;
      norm: string;
      sportClass: string;
    };
    paidHistory: string;
    count: string;
    players: string;
    playersAll: string;
    remarks: string;
    update: {
      player: string;
      remarks: string;
      paidHistory: string;
      sportClassesHistory: string;
    };
    registerForm: string;
    confirm: string;
    sportClasses: string;
    sportClassesUpdate: string;
    suspend: string;
    delete: string;
  };
  titles: string;
  titlesHistoryUpdate: string;
  titlesHistory: string;
  club: {
    clubs: string;
    data: string;
    history: string;
    historyUpdate: string;
    members: string;
    membersUpdate: string;
    paidHistory: string;
    paidUpdate: string;
    remarks: string;
    remarksUpdate: string;
    update: string;
    insert: string;
    suspend: string;
    delete: string;
  };
  arbiter: {
    arbiters: string;
    all_arbiters: string;
    count: string;
    data: string;
    history: string;
    historyUpdate: string;
    titles: string;
    update: string;
    accountApplication: string;
  };
  trainer: {
    count: string;
    data: string;
    history: string;
    historyUpdate: string;
    titles: string;
    trainers: string;
    update: string;
  };
  tournament: {
    insert: {
      tournament: string;
    };
    update: {
      fideTournament: string;
      arbiters: string;
      tournament: string;
      players: string;
      payments: string;
      remarks: string;
    };
    select: {
      tournaments: string;
      tournamentsCount: string;
      data: string;
      players: string;
      norms: string;
      sportClasses: string;
      payments: string;
      remarks: string;
    };
    confirm: string;
    delete: string;
  };
  role: {
    roles: string;
    permissions: string;
    update: string;
    changePassword: string;
  };
  logs: {
    logs: string;
    log: string;
  };
  backup: {
    backups: string;
    create: string;
    download: string;
    delete: string;
    import: string;
  };

  ratingList: {
    download: string;
  };

  financialReports: {
    reports: string;
    report: string;
    notReportedPayments: string;
    new: string;
  };
  countries: string;
  generateArbiterLicense: string;
  generatePlayerLicense: string;
  generateClubLicense: string;
  generateTrainerLicense: string;
  services: {
    services: string;
    tournament: string;
    update: {
      services: string;
      tournament: string;
    };
  };
  currencies: string;
  tables: string;
  refreshFide: string;
}

interface NoMenuURLs {
  player: string;
  newPlayer: string;
  arbiter: string;
  newArbiter: string;
  club: string;
  newClub: string;
  trainer: string;
  newTrainer: string;
  tournament: string;
  newTournament: string;
  userManagement: string;
  changePassword: string;
  log: string;
  confirm: {
    players: string;
    tournaments: string;
  };
  backups: string;
  financialReports: string;
  financialReport: string;
  newFinancialReport: string;
}

interface Settings {
  URLS: Record<string, URLObject>;
  admin_mail: string;
  API: APIEndpoints;
  NO_MENU_URLS: NoMenuURLs;
  ACCOUNT_URLS: Record<string, URLObject>;
  collator: Intl.Collator;
  currencyFormatter: Intl.NumberFormat;
}

const URLS: Record<string, URLObject> = {
  home: { name: "Strona główna", url: "/", icon: faHome },
  arbiters: { name: "Sędziowie", url: "/arbiters/", icon: faGavel },
  trainers: { name: "Trenerzy", url: "/trainers/", icon: faChalkboardUser },
  players: { name: "Zawodnicy", url: "/players/", icon: faUser },
  tournaments: { name: "Turnieje", url: "/tournaments/", icon: faTrophy },
  clubs: { name: "Kluby", url: "/clubs/", icon: faUsers },
  services: { name: "Usługi", url: "/services/", icon: faBellConcierge },
  application: { name: "Wnioski", url: "/application/", icon: faPaperclip },
};

const ACCOUNT_URLS: Record<string, URLObject> = {
  login: { name: "Logowanie", url: "/login/", icon: faRightToBracket },
  logout: { name: "Wyloguj", url: "/logout/", icon: faRightFromBracket },
  panel: { name: "Panel", url: "/panel", icon: faScrewdriverWrench },
};

const NO_MENU_URLS: NoMenuURLs = {
  player: "/player/",
  newPlayer: "/player/new/",
  arbiter: "/arbiter/",
  newArbiter: "/arbiter/new/",
  club: "/club/",
  newClub: "/club/new/",
  trainer: "/trainer/",
  newTrainer: "/trainer/new",
  tournament: "/tournament/",
  newTournament: "/tournament/new/",
  userManagement: "/users/managment/",
  changePassword: "/user/change/pass/",
  log: "/log/",
  confirm: {
    players: "/confirm/players/",
    tournaments: "/confirm/tournaments/",
  },
  backups: "/backups/",
  financialReports: "/reports/",
  financialReport: "/report/",
  newFinancialReport: "/report/new",
};

const API: APIEndpoints = {
  host: "https://api-dev.cr.bazaszachowa.smallhost.pl",
  login: "/login.php",
  logout: "/logout.php",
  roles: "/roles.php",
  refreshSession: "/refresh_session.php",
  sportClasses: "/sport_classes.php",
  player: {
    data: "/player/data.php",
    decodedPlayers: "/player/players_decoded.php",
    insert: {
      player: "/player/insert/player.php",
      norm: "/player/insert/norm.php",
      sportClass: "/player/insert/sport_classes.php",
    },
    paidHistory: "/player/paid_history.php",
    count: "/player/players_count.php",
    players: "/player/players.php",
    playersAll: "/player/players_all.php",
    remarks: "/player/remarks.php",
    update: {
      player: "/player/update/player.php",
      remarks: "/player/update/remarks.php",
      paidHistory: "/player/update/paid_history.php",
      sportClassesHistory: "/player/update/sport_classes_history.php",
    },
    registerForm: "/player/register_form.php",
    confirm: "/player/confirm.php",
    sportClasses: "/player/sport_classes_history.php",
    sportClassesUpdate: "/player/update/sport_classes_history.php",
    suspend: "/player/update/suspend.php",
    delete: "/player/delete.php",
  },
  titles: "/titles.php",
  titlesHistoryUpdate: "/player/update/titles_history.php",
  titlesHistory: "/player/titles_history.php",
  club: {
    clubs: "/club/clubs.php",
    data: "/club/data.php",
    history: "/club/history.php",
    historyUpdate: "/club/update/history.php",
    members: "/club/members.php",
    membersUpdate: "/club/update/members.php",
    paidHistory: "/club/paid_history.php",
    paidUpdate: "/club/update/paid_history.php",
    remarks: "/club/remarks.php",
    remarksUpdate: "/club/update/remarks.php",
    update: "/club/update/club.php",
    insert: "/club/insert.php",
    suspend: "/club/update/suspend.php",
    delete: "/club/delete.php",
  },
  arbiter: {
    arbiters: "/arbiter/arbiters.php",
    all_arbiters: "/arbiter/arbiters_all.php",
    count: "/arbiter/arbiters_count.php",
    data: "/arbiter/data.php",
    history: "/arbiter/history.php",
    historyUpdate: "/arbiter/update/history.php",
    titles: "/titles_arbiter.php",
    update: "/arbiter/update/arbiter.php",
    accountApplication: "/arbiter/account_application.php",
  },
  trainer: {
    count: "/trainer/trainers_count.php",
    data: "/trainer/data.php",
    history: "/trainer/history.php",
    historyUpdate: "/trainer/update/history.php",
    titles: "/titles_trainer.php",
    trainers: "/trainer/trainers.php",
    update: "/trainer/update/trainer.php",
  },
  tournament: {
    insert: {
      tournament: "/tournament/insert/tournament.php",
    },
    update: {
      fideTournament: "/tournament/update/fide_tournament.php",
      arbiters: "/tournament/update/arbiters.php",
      tournament: "/tournament/update/tournament.php",
      players: "/tournament/update/players.php",
      payments: "/tournament/update/payments.php",
      remarks: "/tournament/update/remarks.php",
    },
    select: {
      tournaments: "/tournament/tournaments.php",
      tournamentsCount: "/tournament/tournaments_count.php",
      data: "/tournament/data.php",
      players: "/tournament/players.php",
      norms: "/tournament/norms.php",
      sportClasses: "/tournament/sport_classes.php",
      payments: "/tournament/payments.php",
      remarks: "/tournament/remakrs.php",
    },
    confirm: "/tournament/confirm.php",
    delete: "/tournament/delete.php",
  },
  role: {
    roles: "/role/roles.php",
    permissions: "/role/permissions.php",
    update: "/role/update/roles.php",
    changePassword: "/role/update/password.php",
  },
  logs: {
    logs: "/log/logs.php",
    log: "/log/log.php",
  },
  backup: {
    backups: "/backup/backup_list.php",
    create: "/backup/backup.php",
    download: "/backup/download.php",
    delete: "/backup/delete.php",
    import: "/backup/import.php",
  },
  countries: "/countries.php",
  generateArbiterLicense: "/generate_arbiter_license.php",
  generatePlayerLicense: "/generate_player_license.php",
  generateClubLicense: "/generate_club_license.php",
  generateTrainerLicense: "/generate_trainer_license.php",
  services: {
    services: "/services/services.php",
    tournament: "/services/tournament_services.php",
    update: {
      services: "/services/update/services.php",
      tournament: "/services/update/tournament_services.php",
    },
  },
  ratingList: {
    download: "/rating_list/download.php",
  },

  financialReports: {
    reports: "/financial_reports/reports.php",
    report: "/financial_reports/report.php",
    notReportedPayments: "/financial_reports/not_reported_payments.php",
    new: "/financial_reports/new_report.php",
  },
  currencies: "/currencies.php",
  tables: "/tables.php",
  refreshFide: "/refresh_fide_list.php",
};

const admin_mail: string = "andykrk22@gmail.com";

const collator: Intl.Collator = new Intl.Collator("pl", {
  sensitivity: "variant",
});

const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat("pl-PL", {
  style: "currency",
  currency: "PLN",
});

const SETTINGS: Settings = {
  URLS,
  admin_mail,
  API,
  NO_MENU_URLS,
  ACCOUNT_URLS,
  collator,
  currencyFormatter,
};

export default SETTINGS;
export {
  URLS,
  admin_mail,
  API,
  NO_MENU_URLS,
  ACCOUNT_URLS,
  collator,
  currencyFormatter,
};
