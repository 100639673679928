import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";

import { AppState } from "./types/AppState";
import { ACCOUNT_URLS, API } from "./settings";
import { isProvince } from "./types/Province";

interface URLLoggerProps {
  setState: React.Dispatch<React.SetStateAction<AppState>>;
}

/**
 * URLLogger component handles URL changes and session expiration.
 * It updates the state and manages cookies for session management.
 *
 * @param {URLLoggerProps} props - The component props.
 * @param {React.Dispatch<React.SetStateAction<AppState>>} props.setState - The function to update the state.
 * @returns {null} null.
 */
function URLLogger({ setState }: URLLoggerProps): null {
  const location = useLocation();

  useEffect(() => {
    const token = Cookies.get("token");
    const expiration = Cookies.get("expiration");

    if (token && expiration && parseInt(expiration) > new Date().getTime()) {
      const formData = new FormData();
      formData.append("token", token);

      const refreshSession = async (): Promise<void> => {
        try {
          await fetch(API.host + API.refreshSession, {
            method: "post",
            body: formData,
          });

          Cookies.set("token", token, {
            expires: 1 / 24 / 4,
            secure: true,
            sameSite: "Lax",
          });
          Cookies.set("user", Cookies.get("user") || "", {
            expires: 1 / 24 / 4,
            secure: true,
            sameSite: "Lax",
          });
          Cookies.set(
            "expiration",
            new Date().setMinutes(new Date().getMinutes() + 15).toString(),
            { expires: 1 / 24 / 4, secure: true, sameSite: "Lax" }
          );

          setState(
            (prev: AppState): AppState => ({
              ...prev,
              token:
                Cookies.get("token") === "undefined"
                  ? undefined
                  : Cookies.get("token"),
              expiration:
                Cookies.get("expiration") === "undefined"
                  ? undefined
                  : Cookies.get("expiration"),
              user:
                Cookies.get("user") === "undefined"
                  ? undefined
                  : Cookies.get("user"),
            })
          );

          const rolesResponse = await fetch(API.host + API.roles, {
            method: "post",
            body: formData,
          });
          const roles: { rola: string; województwo: string }[] =
            await rolesResponse.json();

          roles.forEach((role) => {
            setState((prev: AppState): AppState => {
              if (isProvince(role["województwo"])) {
                switch (role.rola) {
                  case "KEKiR":
                    return {
                      ...prev,
                      kekir: role["województwo"],
                    };
                  case "superadministrator":
                    return {
                      ...prev,
                      superadmin: role["województwo"],
                    };
                  case "kolegium sędziów":
                    return {
                      ...prev,
                      arbiterCol: role["województwo"],
                    };
                  case "księgowość":
                    return {
                      ...prev,
                      accountant: role["województwo"],
                    };
                  case "kolegium trenerów":
                    return {
                      ...prev,
                      trainerCol: role["województwo"],
                    };
                  case "sędzia":
                    return {
                      ...prev,
                      arbiter: role["województwo"],
                    };
                  default:
                    return prev;
                }
              }
              return prev;
            });
          });
        } catch {
          Cookies.remove("token");
          Cookies.remove("user");
          Cookies.remove("expiration");
        } finally {
          setState(
            (prev: AppState): AppState => ({
              ...prev,
              ...(Cookies.get("token") !== "undefined" && {
                token: Cookies.get("token"),
              }),
              ...(Cookies.get("expiration") !== "undefined" && {
                expiration: Cookies.get("expiration"),
              }),
              ...(Cookies.get("user") !== "undefined" && {
                user: Cookies.get("user"),
              }),
            })
          );
        }
      };

      refreshSession();
    } else {
      if (Cookies.get("expiration")) {
        Cookies.remove("token");
        Cookies.remove("user");
        Cookies.remove("expiration");
        setState((prev: AppState): AppState => {
          const { token, user, expiration, ...newState } = prev;
          return newState;
        });
        window.location.href = ACCOUNT_URLS.login.url;
      } else {
        Cookies.remove("token");
        Cookies.remove("user");
        Cookies.remove("expiration");
      }
      setState((prev) => ({
        token: undefined,
        user: undefined,
        expiration: undefined,
        ...prev,
      }));
    }
  }, [location, setState]);

  return null;
}

export default URLLogger;
