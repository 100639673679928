import { lazy } from "react";
import { ACCOUNT_URLS, NO_MENU_URLS, URLS } from "./settings";

const Home = lazy(() => import("./screens/Home"));
const NotFound = lazy(() => import("./screens/NotFound"));
const Login = lazy(() => import("./screens/Login"));
const Players = lazy(() => import("./screens/player/Players"));
const Player = lazy(() => import("./screens/player/Player"));
const Logout = lazy(() => import("./screens/Logout"));
const NewPlayer = lazy(() => import("./screens/player/NewPlayer"));
const NewArbiter = lazy(() => import("./screens/arbiter/NewArbiter"));
const Arbiters = lazy(() => import("./screens/arbiter/Arbiters"));
const Arbiter = lazy(() => import("./screens/arbiter/Arbiter"));
const Services = lazy(() => import("./screens/Services"));
const Clubs = lazy(() => import("./screens/club/Clubs"));
const Club = lazy(() => import("./screens/club/Club"));
const NewClub = lazy(() => import("./screens/club/NewClub"));
const Trainers = lazy(() => import("./screens/trainer/Trainers"));
const Trainer = lazy(() => import("./screens/trainer/Trainer"));
const NewTrainer = lazy(() => import("./screens/trainer/NewTrainer"));
const Application = lazy(() => import("./screens/Application"));
const NewTournament = lazy(() => import("./screens/tournament/NewTournament"));
const Tournaments = lazy(() => import("./screens/tournament/Tournaments"));
const Tournament = lazy(() => import("./screens/tournament/Tournament"));
const Panel = lazy(() => import("./screens/Panel"));
const UserManagement = lazy(() => import("./screens/UserManagement"));
const Log = lazy(() => import("./screens/Log"));
const ConfirmationPlayer = lazy(
  () => import("./screens/player/ConfirmationPlayer")
);
const ConfirmationTournament = lazy(
  () => import("./screens/tournament/ConfirmationTournament")
);
const ChangePassword = lazy(() => import("./screens/ChangePassword"));
const Backups = lazy(() => import("./screens/Backups"));
const FinancialReports = lazy(
  () => import("./screens/financialReport/FinancialReports")
);
const FinancialReport = lazy(
  () => import("./screens/financialReport/FinancialReport")
);
const NewFinancialReport = lazy(
  () => import("./screens/financialReport/NewFinancialReport")
);

const SiteMap = [
  { path: URLS.home.url, element: Home },
  { path: URLS.players.url, element: Players },
  { path: NO_MENU_URLS.player + ":playerId", element: Player },
  { path: ACCOUNT_URLS.login.url, element: Login },
  { path: ACCOUNT_URLS.logout.url, element: Logout },
  { path: NO_MENU_URLS.newPlayer, element: NewPlayer },
  { path: NO_MENU_URLS.newArbiter, element: NewArbiter },
  { path: NO_MENU_URLS.newTrainer, element: NewTrainer },
  { path: NO_MENU_URLS.newClub, element: NewClub },
  { path: URLS.arbiters.url, element: Arbiters },
  { path: NO_MENU_URLS.arbiter + ":arbiterId", element: Arbiter },
  { path: URLS.services.url, element: Services },
  { path: URLS.clubs.url, element: Clubs },
  { path: NO_MENU_URLS.club + ":clubId", element: Club },
  { path: URLS.trainers.url, element: Trainers },
  { path: NO_MENU_URLS.trainer + ":trainerId", element: Trainer },
  { path: URLS.application.url, element: Application },
  { path: NO_MENU_URLS.newTournament, element: NewTournament },
  { path: URLS.tournaments.url, element: Tournaments },
  { path: NO_MENU_URLS.tournament + ":tournamentId", element: Tournament },
  { path: ACCOUNT_URLS.panel.url, element: Panel },
  { path: NO_MENU_URLS.userManagement, element: UserManagement },
  { path: NO_MENU_URLS.log, element: Log },
  { path: NO_MENU_URLS.confirm.players, element: ConfirmationPlayer },
  { path: NO_MENU_URLS.confirm.tournaments, element: ConfirmationTournament },
  { path: NO_MENU_URLS.changePassword, element: ChangePassword },
  { path: NO_MENU_URLS.backups, element: Backups },
  { path: NO_MENU_URLS.financialReports, element: FinancialReports },
  { path: NO_MENU_URLS.newFinancialReport, element: NewFinancialReport },
  {
    path: NO_MENU_URLS.financialReport + ":reportId",
    element: FinancialReport,
  },
  { path: "*", element: NotFound },
];
export default SiteMap;
